import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()
    // add your code here, if applicable
    this._replicateTextAreasContent()
  }

  // merge conflict here
  _replicateTextAreasContent() {
    this.element.querySelectorAll('textarea').forEach(textarea => {
      textarea.parentNode.dataset.replicatedValue = textarea.value;
    })
  }
  // end merge conflict here
}
