import ApplicationController from './application_controller'
import Swal from 'sweetalert2'

/* This is the custom StimulusReflex controller for the Timer Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {
  static targets = [ 'showBtn', 'hideBtn', 'timerDiv', 'timeText', 'secondsInput', 'clockIcon', 'flash' ]
  static values = {
    time: Number,
    restartTime: Number,
    isExam: Boolean,
  }

  connect () {
    super.connect();
    this.currentIntervalRef = null;
    this.flashShown = false;

    console.log('CONNECTED TO TIMER')
    console.log('timeValue', this.timeValue)
    console.log('restart timeValue', this.restartTimeValue)
    console.log('has restart timeValue', this.hasRestartTimeValue)

    if (this.timeValue > 0) {
      this.runTimerCountdown()
    } else if (this.hasRestartTimeValue) {
      this.runRestartTimer()
    } else if (this.timeValue < 0 && !this.hasRestartTimeValue) {
      // console.log('third condition!')
      this.dispatch('showExpire')
    }
  }

  runTimerCountdown() {
    const expire = new Date().getTime() + (this.timeValue * 60 * 1000);
    console.log('expire', expire)
    // let intervalRef = null;
    const timer = this.timeTextTarget;
    const secsEl = this.secondsInputTarget;

    secsEl.addEventListener("change", e => {
      // console.log('secs', e.target.value);
      if (parseInt(e.target.value) <= 300) {
        timer.classList.add('text-negative')
      }
    })

    this.currentIntervalRef = setInterval(_ => {
      let current = new Date();
      let count = expire - current;
      // console.log('count ONE', count);
      let s = Math.floor((count /  1000)) % 60;
      let m = Math.floor((count / 60000)) % 60;

      timer.innerText = `${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;

      // if (count < 11000) {
      if (count < 1000 * 60 * 5) {
        // console.log('below 5 mins?')
        this.showFlash()
        secsEl.value = Math.floor((count /  1000));
        secsEl.dispatchEvent(new Event('change'))
      }

      if (count < 0) {
        this.stopCurrentTimer()
        timer.innerText = "00:00";
        timer.classList.add('text-negative')
        console.log('this.isExamValue', this.isExamValue)
        if (this.timeValue > 0) this.stimulate(`${this.isExamValue ? 'Exams' : 'Lessons'}#expire_time_limit`).then(res => {
          this.dispatch('showExpire')
        })
      }
    }, 1000);
  }

  showFlash() {
    // if (!this.flashShown) this.flashTarget.classList.remove('hidden')
    if (!this.flashShown) {
      this.flashShown = true;
      this.flashTarget.classList.remove('hidden')
      setTimeout(() => this.hideFlash(), 1000 * 30)
    }
  }

  hideFlash() {
    console.log('hideFlash', 'isflashShown?', this.flashShown);
    this.flashTarget.classList.add('hidden')
  }

  runRestartTimer() {
    // let intervalRef = null;
    console.log('this.hasRestartTimeValue', this.hasRestartTimeValue);
    this.restartTimeInMs = ((this.hasRestartTimeValue ? this.restartTimeValue : 0) * 60 * 1000);
    const timer = this.timeTextTarget;
    timer.classList.add('text-negative');

    this.currentIntervalRef = setInterval(_ => {
      // let current = new Date().getTime();
      this.restartTimeInMs += 1000;
      // console.log('count at restart', count);
      let s = Math.floor((this.restartTimeInMs /  1000)) % 60;
      let m = Math.floor((this.restartTimeInMs / 60000)) % 60;
      let h = Math.floor((this.restartTimeInMs / (60000 * 60)) % 60)
      let days = Math.floor(this.restartTimeInMs / (1000 * 60 * 60 * 24));

      timer.innerText = `- ${days ? days + 'd ' : ''}${h ? h.toString().padStart(2, "0") + ':' : ''}${m.toString().padStart(2, "0")}:${s.toString().padStart(2, "0")}`;

    }, 1000);
  }

  stopCurrentTimer() {
    console.log('stop current timer')
    clearInterval(this.currentIntervalRef)
  }

  hide() {
    // this.timerDivTarget.classList.add('hidden');
    this.timeTextTarget.classList.add('hidden');
    this.clockIconTarget.classList.remove('hidden');
    this.hideBtnTarget.classList.add('hidden');
    this.showBtnTarget.classList.remove('hidden');
  }

  show() {
    // this.timerDivTarget.classList.remove('hidden');
    this.timeTextTarget.classList.remove('hidden');
    this.clockIconTarget.classList.add('hidden');
    this.hideBtnTarget.classList.remove('hidden');
    this.showBtnTarget.classList.add('hidden');
  }
}
